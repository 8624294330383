define("discourse/plugins/discourse-topic-group-button/discourse/connectors/after-topic-footer-main-buttons/custom-public-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      clickButton() {
        const url = this.siteSettings.topic_group_button_url.replace('<TOPIC_ID>', this.get('topic.id')).replace('<USER_ID>', this.currentUser.id).replace('<USERNAME>', this.currentUser.username);
        window.open(url, '_blank');
      }
    }
  };
});